import { doc, updateDoc, getFirestore } from "firebase/firestore";
import moment from "moment-timezone"; // Import moment-timezone
import React, { useEffect, useState } from "react";
import "../Calendar/Calendar.css";
import emailjs from "emailjs-com";


import {
    calculateAvailability,
    CheckCredits,
    createClassFirestore,
    createGoogleCalendarEvent,
    decrementCredits,
    fetchBlockedSlots,
    getFirestoreData,
    HOURS_END,
    HOURS_START,
    requestAccessTokenWithRefreshToken,
    TIME_SLOT_DURATION,
    updateGoogleCalendarEventIds,
    verifySelectedSlot,
    alreadyTookTrial,
    createFirestorePathForTrialClass,
    sendEmailConfirmation
} from "./utils";

import { handleCancel } from "../utils";
import { use } from "react";
const db = getFirestore();
const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const Calendar = () => {
    const tutorUID = localStorage.getItem("tutorUID");
    const studentUID = localStorage.getItem("studentUID");
    const [tutor, setTutor] = useState(null);
    const [tutorStudents, setTutorStudents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentWeek, setCurrentWeek] = useState(moment().startOf("week"));
    const [slots, setSlots] = useState(new Map());
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [daysToShow, setDaysToShow] = useState(7);
    const [classData, setClassData] = useState(null);
    const [tutorAccessToken, setTutorAccessToken] = useState(null);

    const [student, setStudent] = useState(null);
    const [studentTutor, setStudentTutor] = useState(null);

    const handleNextWeek = () => {
        setCurrentWeek(moment(currentWeek).add(7, "days"));
    };

    const handlePreviousWeek = () => {
        setCurrentWeek(moment(currentWeek).subtract(7, "days"));
    };

    const handleSlotClick = (slot) => {
        const clickedSlot = slots.get(slot.start.format());
        if (clickedSlot.isAvailable) {
            setSelectedSlot(slot);
        }
    };

    const bookTrialClass = async () => {
        if (await alreadyTookTrial(studentUID)) {
            alert("You have already taken the trial.");
            return;
        }
        const validSlot = verifySelectedSlot(selectedSlot, currentWeek);
        if (!validSlot) {
            return;
        }
        await createFirestorePathForTrialClass(studentUID, tutorUID);
        await createClassFirestore(studentUID, tutorUID, classData);
        const { tutorEventId, studentEventId } = await createGoogleCalendarEvent(
            tutor.CLIENT_ID,
            tutor.CLIENT_SECRET,
            tutorAccessToken,
            student,
            selectedSlot,
            userTimeZone
        );
        await updateGoogleCalendarEventIds(
            tutorEventId,
            studentEventId,
            studentUID,
            tutorUID,
            classData
        );
        blockSelectedSlots();
        sendEmailConfirmation(
            student.email, 
            student.name, 
            selectedSlot.start.format("YYYY-MM-DD"), // 📌 Pasa la fecha completa
            selectedSlot.start.format("h:mm A"), // 📌 Pasa la hora separada
            userTimeZone
        );
        
        alert("La clase de prueba ha sido reservada exitosamente.");
        
    };

    const bookNormalClass = async () => {
        await CheckCredits(studentTutor);
        const validSlot = verifySelectedSlot(selectedSlot, currentWeek);
        if (!validSlot) {
            return;
        }
        await createClassFirestore(studentUID, tutorUID, classData);
        const success = await decrementCredits(studentTutor, studentUID, tutorUID);
        if (!success) {
            return;
        }
        const { tutorEventId, studentEventId } = await createGoogleCalendarEvent(
            tutor.CLIENT_ID,
            tutor.C,
            tutorAccessToken,
            student,
            selectedSlot,
            userTimeZone
        );
        await updateGoogleCalendarEventIds(
            tutorEventId,
            studentEventId,
            studentUID,
            tutorUID,
            classData
        );
        blockSelectedSlots();
        sendEmailConfirmation(
            student.email, 
            student.name, 
            selectedSlot.start.format("YYYY-MM-DD"), // 📌 Pasa la fecha completa
            selectedSlot.start.format("h:mm A"), // 📌 Pasa la hora separada
            userTimeZone
        );
        
        alert("La clase ha sido reservada exitosamente.");
    };

    const rescheduleClass = async () => {
        const validSlot = verifySelectedSlot(selectedSlot, currentWeek);
        if (!validSlot) {
            return;
        }
        await createClassFirestore(studentUID, tutorUID, classData);
        await createGoogleCalendarEvent(
            tutor.CLIENT_ID,
            tutor.C,
            tutorAccessToken,
            student,
            selectedSlot,
            userTimeZone
        );
        const rescheduleTutorEventId = localStorage.getItem("rescheduleTutorEventId");
        const classStart = localStorage.getItem("classStart");
        if (!rescheduleTutorEventId || !classStart) {
          console.error(
            "Missing rescheduleTutorEventId or classStart in local storage",
            { rescheduleTutorEventId, classStart }
          );
          return;
        }
        const classDataToBeCancelled = {
            start: classStart,
        }
        await handleCancel(studentUID, classDataToBeCancelled, tutorUID);
        blockSelectedSlots();

        alert("La clase ha sido reprogramada exitosamente.");
    };

    const blockSelectedSlots = () => {
        const updatedSlots = new Map(slots);
        updatedSlots.set(selectedSlot.start.format(), {
            isAvailable: true,
            isBlocked: true,
        });
        // This sets the second 30 minute slot as blocked
        updatedSlots.set(selectedSlot.end.format(), {
            isAvailable: true,
            isBlocked: true,
        });

        setSlots(updatedSlots);
        setSelectedSlot(null);

        updateDoc(doc(db, "users", studentUID), { alreadytooktrial: true });
    };

    const fetchSlots = async (tutor, tutorAccessToken, tutorStudents) => {
        const blockedSlots = await fetchBlockedSlots(
            tutorAccessToken,
            currentWeek,
            tutor.timezone,
            tutor.calendarIds
        );
        const slots = await calculateAvailability(
            currentWeek,
            tutor.timezone,
            tutor.tutorWorkingHoursStart,
            tutor.tutorWorkingHoursEnd,
            tutorStudents,
            blockedSlots
        );
        setSlots(slots);
        setIsLoading(false);
    };

    useEffect(() => {
        const getNeccesaryInfo = async () => {
            if (tutor !== null) {
                return;
            }
            setIsLoading(true);
            const {
                tutor: firebaseTutor,
                student,
                studentTutor,
                tutorStudents,
            } = await getFirestoreData();

            setStudentTutor(studentTutor);
            setTutorStudents(tutorStudents);
            setStudent(student);
            setTutor(firebaseTutor);

            const tutorAccessToken = await requestAccessTokenWithRefreshToken(
                firebaseTutor.CLIENT_ID,
                firebaseTutor.CLIENT_SECRET,
                firebaseTutor.REFRESH_TOKEN
            );
            setTutorAccessToken(tutorAccessToken);
            if (!tutorAccessToken) {
                console.error("Failed to load tutor access token");
                return;
            }

            fetchSlots(firebaseTutor, tutorAccessToken, tutorStudents);
        };

        getNeccesaryInfo();
    }, []);

    useEffect(() => {
        if (tutor && tutorAccessToken && tutorStudents) {
            setIsLoading(true);
            fetchSlots(tutor, tutorAccessToken, tutorStudents);
            setIsLoading(false);
        }
    }, [currentWeek]);

    useEffect(() => {
        const handleResize = () => {
            const portraitMode = window.matchMedia(
                "(max-width: 750px) and (orientation: portrait)"
            ).matches;
            const isMobile = portraitMode;

            // Change the number of days to show based on screen size
            if (isMobile) {
                setDaysToShow(4); // On smaller screens, show 4 days instead of 7
            } else {
                setDaysToShow(7); // On larger screens, show 7 days
            }
        };

        // Check the screen size on initial load
        handleResize();

        // Add event listener for screen resize
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (selectedSlot) {
            setClassData({
                day: selectedSlot.start.format("dddd"),
                hour: selectedSlot.start.format("HH:mm"),
                timezone: userTimeZone,
                cost: studentTutor.cost, // Accessing `cost` directly from the state
                start: selectedSlot.start.toISOString(),
                end: moment(selectedSlot.start).add(1, "hours").toISOString(), // Assuming this is endSlot
                tutorEventId: `${tutorUID + selectedSlot.start.format("ddddHH:mm")}`,
                studentEventId: `${studentUID + selectedSlot.start.format("ddddHH:mm")
                    }`,
            });
        }
    }, [selectedSlot, studentTutor]);

    const weekRange = `${moment(currentWeek).format("MMM DD")} – ${moment(
        currentWeek
    )
        .add(daysToShow - 1, "days")
        .format("MMM DD, YYYY")}`;
    const handleKindOfBooking = () => {
        const action = localStorage.getItem("action");
        if (selectedSlot) {
            const utcStart = selectedSlot.start.clone().utc();
            const utcEnd = selectedSlot.end.clone().utc();

            switch (action) {
                case "schedule":
                    bookNormalClass({ start: utcStart, end: utcEnd });
                    break;
                case "reschedule":
                    rescheduleClass({ start: utcStart, end: utcEnd });
                    break;
                case "booktrial":
                    bookTrialClass({ start: utcStart, end: utcEnd });
                    break;
                default:
                    console.error("Invalid action");
            }

            setSelectedSlot(null);
        }
    };

    // Asegúrate de definir 'action' antes de usarla
    const action = localStorage.getItem("action"); // O ajusta a cómo quieras obtener esta variable

    const actionButtonText =
        action === "schedule"
            ? "Confirm Booking"
            : action === "reschedule"
                ? "Confirm Reschedule"
                : action === "booktrial"
                    ? "Confirm Trial"
                    : "Confirm";

    return (
        <div className={"wholecalendar"}>
            <div className="squarecalendar"></div>
            <div className="week-navigation">
                <button className="previous-next" onClick={handlePreviousWeek}>
                    Previous {daysToShow} Days
                </button>
                <h3 className="week-range">{weekRange}</h3>
                <button className="previous-next" onClick={handleNextWeek}>
                    Next {daysToShow} Days
                </button>
            </div>
            <div>
                {isLoading ? (
                    <div className="loading-screen">
                        <div className="loader"></div>
                        <p>Loading Calendar...</p>
                    </div>
                ) : (
                    <div className="calendar">
                        {Array.from({ length: daysToShow }).map((_, dayIndex) => (
                            <div className="day-column" key={dayIndex}>
                                <h4 className="day-name">
                                    {moment(currentWeek).add(dayIndex, "days").format("dddd")}
                                    {` ${moment(currentWeek).add(dayIndex, "days").format("D")}`}
                                </h4>
                                {Array.from({ length: (HOURS_END - HOURS_START) * 2 }).map(
                                    (_, slotIndex) => {
                                        const hour = HOURS_START + Math.floor(slotIndex / 2);
                                        const minute = (slotIndex % 2) * 30;

                                        const slotStart = moment(currentWeek)
                                            .add(dayIndex, "days")
                                            .set({ hour, minute })
                                            .tz(userTimeZone); // Convert to user's timezone

                                        const slotEnd = moment(slotStart).add(
                                            TIME_SLOT_DURATION,
                                            "minutes"
                                        );

                                        const slot = slots.get(slotStart.format());

                                        const isBlocked = slot?.isBlocked;

                                        const isAvailable = slot?.isAvailable;

                                        const nextSlotStart = moment(slotStart).add(30, "minutes");

                                        const isNextBlocked = slots.get(
                                            nextSlotStart.format()
                                        )?.isBlocked;

                                        const isSelected =
                                            selectedSlot &&
                                            moment(selectedSlot.start).isSame(slotStart);
                                        const isNextSelected =
                                            selectedSlot &&
                                            moment(selectedSlot.start)
                                                .add(30, "minutes")
                                                .isSame(slotStart);

                                        const canSelect =
                                            isAvailable && !isNextBlocked && !isBlocked;

                                        let slotClass = "available";
                                        if (slot && !isAvailable) {
                                            slotClass = "unavailable";
                                        } else if (isBlocked) {
                                            slotClass = "blocked";
                                        }

                                        return (
                                            <div
                                                key={slotIndex}
                                                className={`time-slot ${slotClass} ${isSelected || isNextSelected ? "selected" : ""
                                                    }`}
                                                onClick={
                                                    canSelect
                                                        ? () =>
                                                            handleSlotClick({
                                                                start: slotStart,
                                                                end: slotEnd,
                                                            })
                                                        : null
                                                }
                                            >
                                                {slotStart.format("h:mm A")}
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {selectedSlot && (
                <div className="booking-confirmation">
                    <p>
                        Your 55 minute class will start at:
                        <br /> <b>{selectedSlot.start.format("h:mm A")}</b>
                    </p>
                    <button onClick={handleKindOfBooking}>{actionButtonText}</button>
                    <button onClick={() => setSelectedSlot(null)}>Cancel</button>
                </div>
            )}
        </div>
    );
};

export default Calendar;
