import { useEffect } from "react";
import { requestAccessTokenWithRefreshToken } from "../components/Calendar/utils";
import { getFirestoreData } from "../components/Calendar/utils";
export function useSetAccessTokens() {
  useEffect(() => {
    const fetchData = async () => {
      const { tutor, student } = await getFirestoreData();

      const tutorAccessToken = await requestAccessTokenWithRefreshToken(
        tutor.CLIENT_ID,
        tutor.CLIENT_SECRET,
        tutor.REFRESH_TOKEN
      );
      localStorage.setItem("google_tutor_access_token", tutorAccessToken);

      const studentAccessToken = await requestAccessTokenWithRefreshToken(
        student.CLIENT_ID,
        student.CLIENT_SECRET,
        student.REFRESH_TOKEN
      );
      localStorage.setItem("google_student_access_token", studentAccessToken);
    };
    fetchData();
  }, []);
}