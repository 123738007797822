import React, { useEffect, useState } from 'react';
import './Students.css';
import { getFirestore, doc, getDocs, query, collection, where, getDoc, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { handleCancel } from '../utils';

const db = getFirestore();
const auth = getAuth();

const Students = () => {
    const [students, setStudents] = useState([]);
    const [error, setError] = useState(null);
    const [showClasses, setShowClasses] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const fetchStudents = async () => {
            if (!auth.currentUser) {
                setError("El usuario no está autenticado.");
                return;
            }
            const tutorUID = auth.currentUser.uid;
            const studentsRef = collection(db, "users");
            const studentsQuery = query(studentsRef, where("role", "==", "student"));

            try {
                const studentSnapshot = await getDocs(studentsQuery);
                const studentList = [];
                
                await Promise.all(studentSnapshot.docs.map(async (studentDoc) => {
                    const studentUID = studentDoc.id;
                    const tutorRef = doc(db, `users/${studentUID}/tutors/${tutorUID}`);
                    const tutorDoc = await getDoc(tutorRef);
                    if (tutorDoc.exists()) {
                        const tutorData = tutorDoc.data();
                        const sortedClasses = (tutorData.scheduledClasses || []).sort((a, b) => new Date(b.start) - new Date(a.start));
                        studentList.push({
                            uid: studentUID,
                            name: studentDoc.data().name,
                            credits: tutorData.credits || 0,
                            scheduledClasses: sortedClasses,
                        });
                    }
                }));
                
                setStudents(studentList);
            } catch (error) {
                console.error("Error fetching students: ", error);
                setError("Error fetching students: " + error.message);
            }
        };

        fetchStudents();
    }, []);

    const toggleShowClasses = (studentUID) => {
        setShowClasses(prev => ({
            ...prev,
            [studentUID]: !prev[studentUID]
        }));
    };

    const handleReschedule = (classItem, studentUID) => {
        localStorage.setItem('studentUID', studentUID);
        localStorage.setItem('tutorUID', auth.currentUser.uid);
        localStorage.setItem('classStart', classItem.start);
        localStorage.setItem('action', 'reschedule');
        navigate("/calendar");
    };

    const handleCancelClick = async (studentUID, classItem, classIndex) => {
        if (new Date(classItem.start) < new Date()) {
            console.warn("Cannot cancel past classes.");
            return;
        }
        try {
            await handleCancel(studentUID, classItem, auth.currentUser.uid);
            
            setStudents(prevStudents => prevStudents.map(student => {
                if (student.uid === studentUID) {
                    return {
                        ...student,
                        scheduledClasses: student.scheduledClasses.filter((_, index) => index !== classIndex),
                        credits: student.credits + 1,
                    };
                }
                return student;
            }));
        } catch (error) {
            console.error("Error canceling class: ", error);
        }
    };

    return (
        <div className="students-container">
            {error && <p className="error-message">{error}</p>}
            <h2 className="students-title">My Students</h2>
            <ul className="student-list">
                {students.map((student) => (
                    <li key={student.uid} className="student-card">
                        <div className="student-header">
                            <img
                                src="/img/defaultprofilepic.png"
                                alt={`${student.name}'s profile`}
                                className="profile-pic-student"
                            />
                            <div className="student-info">
                                <div className="student-name">{student.name}</div>
                                <div className="student-credits">Credits: {student.credits}</div>
                            </div>
                            <button onClick={() => toggleShowClasses(student.uid)}>
                                {showClasses[student.uid] ? 'Hide Classes ▲' : 'Show Classes ▼'}
                            </button>
                        </div>
                        {showClasses[student.uid] && (
                            <div className="classes-container">
                                <h3>Scheduled Classes:</h3>
                                {student.scheduledClasses.length > 0 ? (
                                    student.scheduledClasses.map((classItem, index) => (
                                        <div key={index} className="class-item">
                                            <p>{new Date(classItem.start).toLocaleString()}</p>
                                            {new Date(classItem.start) > new Date() && (
                                                <>
                                                    <button onClick={() => handleReschedule(classItem, student.uid)}>
                                                        Reschedule
                                                    </button>
                                                    <button onClick={() => handleCancelClick(student.uid, classItem, index)}>
                                                        Cancel
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    <p>No scheduled classes</p>
                                )}
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Students;